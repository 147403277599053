@import 'styles/colors';
@import 'styles/constants';

.AcceptInvitation {
  &__header {
    padding: 25px 0 25px 0;
  }

  &__form {
    .Input--container {
      margin-bottom: 20px;
    }

    &--button {
      margin-top: 40px;
    }
  }
}
