@import 'styles/colors';

.DropdownMenu {
  padding-bottom: 15px;
  &__header-container {
    color: $color-gray-3;
    height: 48px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    cursor: pointer;
    &--active,
    &:hover {
      color: $color-black;
      background-color: $color-gray-1;

      .NavbarItem__icon {
        color: $color-blue;
      }

      .NavbarItem__marker {
        background: $color-blue;
      }
    }
    &__marker {
      height: 48px;
      width: 48px;
    }
  }
  &__header {
    display: flex;
    flex-grow: 1;
    align-items: center;
    color: #c1c2c7;
    font-size: 13px;
    font-weight: 600;
  }
  &__container {
    display: none;
    &.DropdownMenu__container--active {
      display: block;
    }
  }

}
