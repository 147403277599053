@import 'styles/colors';

.CampaignDetailsShareUrl {
  width: 558px;

  &__info {
    color: $color-gray-4;
    line-height: 27px;
    padding-bottom: 22px;
    white-space: pre-line;
  }

  &__share-container {
    display: flex;
  }

  &__link {
    font-weight: 600;

    &--container {
      margin-top: 29px;
      display: flex;
      flex: 1;
      justify-content: center;
      align-self: center;
    }
  }
}
