@import 'styles/mixins';

.EmissionPlanFilters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 20px 5px 20px 5px;

  & > div {
    flex-grow: 1;
  }
}
