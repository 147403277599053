@import 'styles/mixins_table';
@import 'styles/colors';

@include common-table;
.CampaignTable {
  &__agency-name {
    &--td {
      max-width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &--tooltip {
      position: absolute;
      z-index: 2;
      width: 100%;
    }
  }
  &__status {
    display: flex;
    align-items: center;

    &--icon {
      margin-right: 15px;
    }
  }

  &__alert {
    display: flex;
    justify-content: center;
  }

  &__alerts-icons-container {
    justify-content: center;
    display: flex;
    gap: 6px;
  }

  $progress-bar-height: 2px;
  &__progress-bar {
    position: relative;
    width: 100%;
    top: 17.5px - $progress-bar-height - 8px;

    &--progress,
    &--background {
      position: absolute;
      height: $progress-bar-height;
    }

    &--progress {
      z-index: 1;
    }

    &--background {
      background-color: $color-gray-2;
      width: inherit;
    }
  }
  &__header-item--wrapped {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    display: flex;
    align-items: center;
  }
}
