@import 'styles/colors';

$gap: 10px;

.CampaignDetailsHistory {
  &__container {
    padding: $gap;
    max-height: 472px;
    overflow-y: scroll;
  }

  &__no-data {
    color: $color-gray-4;
    text-align: center;
  }
}

.HistoryLogItem {
  display: flex;
  gap: $gap;
  padding: $gap;

  &__date {
    font-weight: bold;
    white-space: nowrap;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: $gap * 2;
  }

  &__alter-value {
    display: contents;
    font-weight: bold;

    &--empty {
      color: $color-gray-5;
    }
  }
}
