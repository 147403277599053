@import 'styles/colors';

.TopBarLanguage {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  width: 100px;
  align-items: center;
  padding-right: 25px;

  &__img {
    object-fit: cover;
    width: 30px;
    height: 30px;
    border-radius: 15px;

    &--container {
      width: 30px;
    }
  }

  &__Dropdown {
    &--placeholder {
      display: none;
    }

    &--control {
      border: 0 none;
      padding: 12px;
      cursor: pointer;

      &:hover {
        box-shadow: none;
      }
    }

    &--arrow {
      width: 9px !important;
      color: $color-gray-4;
    }

    &--menu {
      width: fit-content;
      background-color: $color-white;
      border: solid 1px $color-gray-2;
      border-radius: 6px;
      color: $color-black;
      margin: 0 0 0 12px;
    }

    &--option {
      &.is-selected {
        background-color: $color-gray-2;
      }

      &:hover {
        background-color: $color-blue;
        color: $color-white;
      }
    }
  }
}
