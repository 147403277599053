@import 'styles/colors';

.NavbarItem {
  display: flex;
  flex-grow: 1;
  align-items: center;

  &__container {
    display: flex;
    justify-content: space-between;
    height: 48px;
    margin-bottom: 15px;
    color: $color-gray-3;
    align-items: center;
    text-decoration: none;
    font-size: 13px;
    font-weight: 600;

    &--active,
    &:hover {
      color: $color-black;
      background-color: $color-gray-1;
      border: 1px solid $color-gray-2;
      border-width: 1px 0 1px 0;

      .NavbarItem__icon {
        color: $color-blue;
      }

      .NavbarItem__marker {
        background: $color-blue;
      }
    }
  }

  &__marker {
    width: 3px;
    height: 48px;
    margin-right: 40px;
  }

  &__text {
    padding-left: 15px;
  }

  &__icon {
    font-size: 24px;

    &--container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
    }
  }
}
