@import 'styles/colors';

.TableHeader {
  color: $color-gray-3;

  &--sticky {
    position: sticky;
    top: 0;
    background-color: $color-white;
  }

  &__row {
    background-color: transparent;
    height: fit-content;
    font-size: 12px;
    color: $color-gray-4;
  }
}
