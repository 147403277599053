.Alerts {
  &__alert {
    padding-bottom: 5px;
    &:last-of-type {
      padding-bottom: 0px;
    }

    &--index {
      font-weight: 600;
    }

    &--hoverable {
      padding: 10px;
    }
  }
}
