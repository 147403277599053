@import 'styles/colors';
@import 'styles/constants';

$right-space: 50px;

.TopBarUser {
  display: flex;
  align-items: center;
  cursor: pointer;

  &--container {
    display: flex;
    margin-right: $right-space;
  }

  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 12px;
    object-fit: cover;
  }

  &__name {
    font-size: 12px;
    font-weight: 600;
  }

  &__arrow {
    width: 9px !important;
    color: $color-gray-4;
    padding-left: 12px;
  }

  &__dropdown {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--container {
      position: absolute;
      width: 294px;
      top: $topbar-height;
      right: $right-space;
      border: solid 1px $color-gray-2;
      border-radius: 6px;
      background-color: $color-white;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }

    &--avatar {
      height: 105px;
      width: 105px;
      margin-top: 25px;
      position: relative;

      &-img {
        height: inherit;
        width: inherit;
        border-radius: 100%;
        object-fit: cover;
      }

      &-upload {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        color: $color-white;
        border: solid 1px $color-blue;
        position: absolute;
        right: -8px;
        bottom: 8px;
        background-color: $color-white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &-icon {
          font-size: 20px;
          color: $color-blue;
        }
      }
    }

    &--data {
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-name,
      &-email {
        padding-bottom: 8px;
      }

      &-name,
      &-role {
        font-weight: 600;
      }
    }

    &--settings {
      width: 100%;
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid $color-gray-2;
      border-bottom: 1px solid $color-gray-2;

      &-icon {
        color: $color-blue;
        padding-right: 8px;
      }

      &-text {
        color: $color-black;
      }
    }

    &--sign-out {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 20px 0;
      background-color: $color-gray-1;
    }
  }

  &__upload-modal {
    height: 346px;
  }
}
