@import 'styles/colors';

.StripeCardDouble {
  margin-bottom: 15px;
  &__card {
    border-color: var(--border-color);
    border-left: 15px solid var(--border-color);
    padding: 0;
  }

  &__top, &__bottom {
    display: grid;
    grid-template-columns: 39px auto;
    padding: 30px 30px 30px 22px;
  }
  &__bottom {
    padding-left: 59px;
    display: flex;
    border-top: 1px solid var(--border-color);
  }
  &__content-container {
    display: flex;
    flex-direction: column;
  }
  &__title {
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
  }
  &__container {
    width: 100%;
  }

  &__top-content-container, &__bottom-content {
    overflow-y: auto;
  }
}
