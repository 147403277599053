@import 'styles/colors';

@mixin inner-circle($color) {
  width: 8px;
  height: 8px;
  background-color: $color;
  margin: 3px 0 0 3px;
}

.RadioButton {
  display: flex;
  align-items: center;
  width: fit-content;

  &__input {
    -webkit-appearance: none;
    min-width: 18px;
    height: 18px;
    border: 1px solid $color-gray-3;
    border-radius: 50%;
    margin: 0;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &:not([disabled]) {
      &:hover,
      &:checked {
        border: 2px solid $color-blue;
      }

      &:checked {
        &:before {
          @include inner-circle($color-blue);
        }
      }
    }

    &:disabled {
      cursor: default;

      &:checked {
        border: 2px solid $color-gray-3;

        &:before {
          @include inner-circle($color-gray-3);
          background-color: $color-gray-3;
        }
      }
    }
  }

  &__label {
    margin-left: 15px;
    cursor: pointer;

    &--disabled {
      cursor: text;
    }
  }
}
