.InventoryDetailsModal {
  .Modal__content {
    max-width: 800px;
    width: 100%;
    margin: 20px;
    max-height: 95vh;
    overflow-y: auto;
  }

  &__agglomeration {
    display: grid;
    grid-template-columns: minmax(60px, 4fr) minmax(100px, 20fr) 1fr minmax(122px, 5fr);
    gap: 20px;
    height: 28px;
    width: 100%;
  }

  &__common-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: center;

    &--bold {
      font-weight: bold;
    }
  }

  &__agglomeration-value {
    align-self: center;
  }

  &__agglomeration-bar {
    background: #D8D9DD;
    position: relative;
    width: 100%;
    height: 12px;
    border-radius: 20px;
    align-self: center;
  }

  &__agglomeration-filling {
    position: absolute;
    height: 12px;
    border-radius: 20px;
    background: linear-gradient(90deg, #90E0EF 0%, #2A77B6 100%);
  }
}
