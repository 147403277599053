@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/constants';

.Modal {
  &__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 0 30px;

    &--separate {
      border-bottom: solid 1px $color-gray-2;
      border-radius: 6px 6px 0 0;
      background-color: $color-gray-1;
      padding: 20px 30px;
    }

    &--title {
      font-size: 24px;
      font-weight: 600;
      word-break: break-all;

      &-separate {
        font-size: 18px;
      }
    }
  }

  &__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba($color-black, 0.5);
    z-index: $modal-z-index;
  }

  &__content {
    width: min-content;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    outline: none;
    background-color: rgb(255, 255, 255);
    border: solid 1px $color-gray-2;

    &--creations-form-upload {
      width: auto;
    }

    &--acceptances-list {
      .Modal__children {
        padding: 10px;
      }
    }
  }

  &__children {
    padding: 20px 30px;

    &--scrollable {
      @include scrollbar();

      overflow: auto;
      padding: 0 20px 0 0;
      margin: 20px 10.5px 20px 30px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 25px;
  }
}
