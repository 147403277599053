@import 'styles/colors';
@import 'styles/constants';

.ChangeEmailModal {
  width: 600px;
  min-height: 90px;
  max-height: calc($modal-max-height + 120px);

  &__footer {
    .Checkbox__title {
      padding-left: 7px;
    }
  }

  &__template {
    &-footer {
      display: flex;
      gap: 10px;
    }

    &-label {
      display: flex;
      justify-content: space-between;
    }
  }
}

.ChangeEmailContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  .FormRow {
    width: 100%;

    .Input--container {
      width: 100%;
    }

    .Input {
      width: 100%;
    }
  }
}
