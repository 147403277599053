@import 'styles/colors';
@import 'styles/constants';

$border: 1px solid $color-gray-2;

.Realization {
  font-weight: 600;
  border-bottom: $border;

  &--highlight {
    color: $color-blue;
  }

  &__spinner {
    padding: 30px 0;
  }

  &__title {
    white-space: nowrap;
    padding-bottom: 5px;
  }

  &__budget {
    padding: 30px;
    display: flex;
    max-width: fit-content;
    border-right: 1px solid $color-gray-2;

    @media (max-width: $breakpoint-l) {
      justify-content: center;
      max-width: 100%;
      border-right: none;
    }

    &--img {
      padding-right: 5px;
    }
  }

  &__current-value {
    &__img {
      transform: translateY(-2px);
      width: 48px;
      padding-right: 13px;
    }

    &__value {
      color: $color-blue;
      white-space: nowrap;
      font-size: 24px;
    }
  }

  &__emissions {
    display: flex;
    max-width: fit-content;
    padding: 30px;

    @media (max-width: $breakpoint-l) {
      justify-content: center;
      max-width: 100%;
    }

    &--img {
      padding-right: 5px;
    }

    &--left {
      border-right: $border;

      @media (max-width: $breakpoint-l) {
        border-right: none;
      }
    }

    &--right {
      border-left: $border;

      @media (max-width: $breakpoint-l) {
        border-left: none;
      }
    }

    &__value {
      color: $color-blue;
      white-space: nowrap;
      font-size: 24px;
    }
  }

  &__progress {
    padding: 30px;

    &__bar {
      padding-top: 5.5px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
    }

    &__time {
      font-size: 12px;
      color: $color-gray-4;
    }
  }
}

.Realization-top {
  flex-wrap: wrap;
  border-bottom: $border;
  padding: 30px;

  &__target {
    white-space: nowrap;
    padding: 0 30px 0 0;
    font-size: 18px;
  }

  &__term, &__emission-type {
    padding-left: 30px;
    font-size: 18px;
  }

  &__estimated-value {
    white-space: nowrap;
    padding: 0 30px 0 0;
    font-size: 18px;
  }
}

.Realization-bottom {
  flex-wrap: wrap;

  @media (max-width: $breakpoint-l) {
    flex-direction: column;

    & > div:nth-last-child(n+2) {
      border-bottom: $border;
    }
  }
}
