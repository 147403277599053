@import 'styles/colors';
@import 'styles/constants';
@import 'styles/mixins';

.TopBar {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1010; // because Leaflet map controls
  height: $topbar-height;
  border-bottom: 1px solid $color-gray-2;
  background-color: $color-white;

  &__logo {
    margin-left: 58px;
    align-self: center;

    &--img {
      height: 48px;
    }
  }
}
