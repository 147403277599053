@import 'styles/colors';

.MediaPopup {
  &__label {
    font-weight: 600;
    padding-bottom: 5px;
  }

  &__data {
    padding: 0 0 5px 5px;
  }

  &__img {
    &--container {
      display: flex;
      justify-content: center;
    }

    max-width: 200px;
    padding-top: 10px;
  }
}
