@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/mixins-react-select';

.SelectAsync {
  @include clear-indicator();
  @include dropdown-indicator();
  @include input();
  @include menu();
  @include option();
  @include placeholder();
  @include single-value();
  @include value-container();
  @include error('SelectAsync');

  font-family: 'Open Sans' !important;
  font-size: 14px !important;
  caret-color: $color-gray-5;
  width: fit-content;

  &--bold {
    font-weight: 600;
  }

  &__container {
    position: relative;
  }

  &--is-disabled {
    .SelectAsync__value-container {
      line-height: normal;
      align-self: center !important;
    }

    .SelectAsync__multi-value {
      box-shadow: inset 0 0 0px 1px $color-gray-3;
    }

    .SelectAsync__multi-value__label {
      padding: 0 10px !important;
    }

    .SelectAsync__multi-value__remove {
      display: none;
    }
  }

  &__value-container {
    .css-b8ldur-Input {
      margin: 0 !important;
    }

    &--is-multi {
      padding: 6px !important;
      line-height: 32px;

      .SelectAsync__placeholder,
      .css-b8ldur-Input {
        margin-left: 5px !important;
      }
    }
  }

  &__multi-value {
    display: flex;
    align-items: center;
    height: 26px;
    margin: 5px !important;
    border-radius: 4px !important;
    background-color: $color-gray-2 !important;
    font-size: 13px;
    font-weight: 600;

    &__label {
      padding: 0 6px 0 10px !important;
      font-weight: 600;
      font-size: 100% !important;
      color: $color-gray-5 !important;

      cursor: default;
    }

    &__remove {
      padding-left: 0 !important;
      color: $color-gray-4 !important;
      cursor: pointer;

      &:hover {
        background-color: transparent !important;
        color: $color-red !important;
      }
    }
  }

  &__control {
    @include control();
    min-width: 375px;
    min-height: 50px !important;
    height: fit-content;
  }
}
