@import 'styles/colors';

.ProgressBarIndicator {
  position: absolute;

  &--background {
    position: absolute;
    top: -15px;
    left: 0px;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    background-color: $color-blue;
    opacity: 0.4;
  }

  &--circle {
    position: absolute;
    top: -10px;
    left: 5px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: $color-green;
    border: solid 2px $color-blue;
  }
}
