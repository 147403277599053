@import 'styles/colors';

.TableData {
  padding: 0 10px;
  background-color: $color-white;

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }
}
