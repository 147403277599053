@import 'styles/colors';

.PageNotFound {
  display: flex;

  &__left {
    width: 360px;
    padding-top: 93px;

    &--header {
      font-size: 100px;
      font-weight: 600;
      padding-bottom: 26px;
    }

    &--text {
      font-size: 20px;
      padding-bottom: 62px;
    }
  }

  &__right {
    height: 550px;
    padding-top: 32px;
  }
}
