.EmissionSummaryDetails {
  .Modal__content {
    max-width: 800px;
    width: 100%;
    margin: 20px;
    max-height: 95vh;
    overflow-y: auto;
  }

  &__hour-row.EmissionSummaryDetails__hour-row {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }

  .CommonTable__data-row {
    height: 22px;
    cursor: default;
  }

  .CommonTable__data-row:hover .CommonTable__data-item {
    background-color: transparent;
  }

  &__creation {
    padding-top: 28px;
    max-width: 60px;
  }

  &--last-row {
    padding-bottom: 28px;
  }

  &--center {
    text-align: center;
  }

  &__last-row {
    padding-bottom: 24px;
  }
}
