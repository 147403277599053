.CampaignAcceptanceFields {
  &__button {
    margin-top: 13px;
  }

  &__checkboxes {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;

    & > * {
      padding-bottom: 35px;
    }
  }

  &__checkbox {
    min-width: 200px;

    &--title {
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 17px;
    }
  }
}
