@import 'styles/mixins_table';
@import 'styles/mixins';
@import 'styles/colors';

.filter-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  padding-bottom: 15px;

  &__email_input {
    display: flex;
    align-items: flex-end;
  }
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding-bottom: 15px;
}

.SelectMultiCheckbox {
  display: flex;

  &__control {
    flex-grow: 1;
  }

  &__menu {
    // TODO we need to refactor SelectMultiCheckbox.scss
    //  because there is no other way to overwrite it - all styles there are 'important'
    width: 100% !important;
  }
}
