@import 'styles/colors';
@import 'styles/constants';

.Slider {
  position: relative;
  width: $slider-width;

  &__title {
    padding-bottom: 24px;
  }

  &__handle {
    &--indicator {
      top: 15px;

      .ProgressBarIndicator--circle {
        background-color: $color-white;
      }
    }

    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    font-weight: 600;
    color: $color-blue;
    margin-top: -22px;
  }

  .rc-slider {
    &-handle {
      background: none;

      &:active {
        box-shadow: none;
      }
    }

    &-mark,
    &-dot {
      display: none;
    }
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    color: $color-gray-4;
    padding-top: 8px;
  }
}
