@import 'styles/colors';
@import 'styles/constants';

.DetailsForm {
  padding: 0 0 35px 15px;

  &--hidden {
    display: none;
  }

  &__row {
    padding-top: 35px;

    &--locations {
      flex-direction: column;
    }
    &__media-duration, &__media-duration--disabled {
      padding: 10px 0;
      width: auto;

      .Slider__handle::after {
        content: "s"
      }
    }
  }

  &__input {
    width: 340px;

    &--select {
      .SelectAsync {
        &__control {
          width: 340px;
        }

        &__value-container {
          padding: 8px;
        }

        &__value-container,
        &__indicators {
          align-self: flex-start;
        }

        &__placeholder {
          color: $color-black !important;
        }
      }
    }
  }

  &__media {
    display: flex;
    align-items: center;

    &--list {
      font-weight: 600;
    }
  }

  &__media--list--padded {
    padding-left: 30px;
  }

  &__mediaSelection {
    &--modal {
      width: 700px;
      height: $modal-max-height;
      max-height: $modal-max-height;
    }

    &--modal-dg-in {
      width: 900px;
    }

    &--footer {
      display: flex;

      .Button:first-child {
        margin-right: 25px;
      }
    }
  }

  &__hiddenTarget--link {
    display: inline-block;
    padding: 10px 0 24px 0;
  }

  &__suggestedTarget {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px 0 0;

    &__spinner {
      width: 10px;
      height: 10px;
    }
  }
}
