@import 'styles/colors';
@import 'styles/mixins';

.CardHeader {
  @include avoid-break-inside-while-print();
  height: 46px;
  padding: 0 15px;
  background: $color-gray-1;
  color: $color-black;
  display: flex;
  align-items: center;

  &__heading {
    margin: 0;
    font-size: 18px;
    height: 25px;
    font-weight: 600;
  }

  &__status {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;

    &--text {
      margin-right: 5px;
      font-weight: 600;
    }

    &--red {
      color: $color-red;
    }

    &--green {
      color: $color-green;
    }

    &--fade-in {
      @include fade-in-animation();
    }
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      .CardHeader__collapse-btn {
        background-color: $color-blue;
      }

      .CardHeader__collapse-icon {
        color: $color-white;
      }
    }
  }

  &--collapsed {
    margin-bottom: 11px;
  }

  @include collapse-icon('CardHeader');
  &__collapse {
    &-btn {
      @include box-shadow($color-gray-3);
      &--no-shadow {
        box-shadow: none;
        cursor: pointer;
      }
      &--container {
        padding-left: 22px;
        margin-left: auto;
      }
    }

    &-icon {
      color: $color-black;
    }
  }

  &__link {
    margin-left: 20px;
  }
}
