@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/mixins-react-select';

.Select__container {
  .Select {
    @include clear-indicator();
    @include dropdown-indicator();
    @include input();
    @include menu();
    @include option();
    @include placeholder();
    @include single-value();
    @include value-container();
    @include placeholder();
    @include error('Select');

    &__control {
      @include control();
      min-width: 375px;
      min-height: 50px;
      height: fit-content;
      cursor: pointer !important;
    }
  }
}
