$color-blue: #437cf9;
$color-navy-blue: #3a4598;
$color-red: #fd4b84;
$color-green: #29ccc0;
$color-green-dark: #137e83;
$color-purple: #a9b3e3;
$color-yellow: #d2d926;
$color-orange: #dd661b;
$color-white: #ffffff;
$color-gray-1: #f3f4f7;
$color-gray-2: #e5e6eb;
$color-gray-3: #c1c2c7;
$color-gray-4: #9fa2a5;
$color-gray-5: #4d4d4d;
$color-black: #262626;

$color-chart-area: #8dc6f7;
$color-chart-bar: #75b3fb;

$color-pdf-header: #e6f4fc;

:export {
  blue: $color-blue;
  navyBlue: $color-navy-blue;
  red: $color-red;
  green: $color-green;
  greenDark: $color-green-dark;
  purple: $color-purple;
  yellow: $color-yellow;
  orange: $color-orange;

  chartArea: $color-chart-area;
  chartBar: $color-chart-bar;

  pdfHeader: $color-pdf-header;

  white: $color-white;
  gray1: $color-gray-1;
  gray2: $color-gray-2;
  gray3: $color-gray-3;
  gray4: $color-gray-4;
  gray5: $color-gray-5;
  black: $color-black;

  chartFree: $color-gray-2;
  chartPrio3: #CAF0F8;
  chartPrio4: #90E0EF;
  chartPrio5: #48CAE4;
  chartPrio6: #0077B6;
  chartPrio7: #04545E;
  chartPrio8: #03045E;;

  chartPrio3Hover: #90E0EF;
  chartPrio4Hover: #48CAE4;
  chartPrio5Hover: #0077B6;
  chartPrio6Hover: #005F8A;
  chartPrio7Hover: #003B46;
  chartPrio8Hover: #02033D;
}
