.InventoryTopTenChart {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  padding: 8px;
  margin-bottom: 12px;

  &__agglomeration {
    display: grid;
    grid-template-columns: minmax(100px, 5fr) minmax(100px, 20fr) 1fr;
    gap: 20px;
    height: 28px;
    width: 100%;
  }

  &__agglomeration-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
  }

  &__agglomeration-value {
    align-self: center;
  }

  &__agglomeration-bar {
    background: #D8D9DD;
    position: relative;
    width: 100%;
    height: 12px;
    border-radius: 20px;
    align-self: center;
  }

  &__agglomeration-filling {
    position: absolute;
    height: 12px;
    border-radius: 20px;
    background: linear-gradient(90deg, #90E0EF 0%, #2A77B6 100%);
  }
}
