@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/mixins-chart';

.CampaignDetailsChart {
  padding: 30px 15px 0px 15px;

  &--loading {
    padding-bottom: 19px;
  }

  &__no-data {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 25px;
  }

  &__chart {
    padding-right: 30px;
  }

  &__label {
    font-size: 11px;
    color: $color-gray-4;
    padding-bottom: 5px;
    padding-left: 15px;
  }

  @include axis-tick();
  @include tooltip();
  @include chart-legend(65);

  &__legend {
    padding-bottom: 30px;

    &--symbol {
      &-emitted-views {
        background-color: $color-chart-bar;
      }

      &-estimated-audience {
        background-color: $color-gray-4;
      }
    }
  }
}
