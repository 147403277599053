.User {
  &__form {
    .Card {
      width: calc(435px + 2px);
    }

    .FormRow {
      padding: 35px 0 0 15px;
    }

    .Button {
      margin: 40px 0 30px 15px;
    }
  }
}
