@import 'styles/colors';

:root {
  --border-color: #{$color-gray-2};
}

.StripeCard {
  &__card {
    display: flex;
    border-color: var(--border-color);
    border-left: 15px solid var(--border-color);
    padding: 30px 30px 30px 22px;
    margin-bottom: 15px !important;
  }

  &__icon {
   margin-right: 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
  }

  &__container {
    width: 100%;
  }

  &__body {
    //max-height: 200px;
    overflow-y: auto;
  }
}
