@import '../../../../../styles/mixins';

.CreationsFormSchedule {
  &__calendar {
    display: flex;
    .DayPicker {
      border: none;
    }
  }

  &__list {
    padding-left: 70px;

    &--header {
      display: flex;
      align-items: center;
      height: 48px;
      font-weight: 600;
    }

    &--item {
      padding-bottom: 10px;
    }
  }
}
