@import 'styles/mixins';

.Report {
  &__container {
    overflow: hidden;
    width: calc(100vw - 340px);

    @media (max-width: 1020px) {
      width: calc(100vw - 284px);
    }
  }

  &__title {
    font-weight: bold;
  }

  &__loading {
    padding: 15px 0;
  }

  &__no-data {
    display: flex;
    padding: 15px;
    justify-content: center;
  }

  &__info {
    padding-bottom: 15px;
  }

  &__table {
    @include scrollbar(12px);
    overflow: auto;
    margin: 15px;

    .TableHeaderItem {
      white-space: normal;
      padding-bottom: 10px;

      &:last-child {
        padding-right: 15px;
      }
    }

    .TableData {
      white-space: nowrap;
      padding-bottom: 5px;
    }
  }

  &__form {
    padding: 15px 15px 0 15px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: minmax(100px, auto);

    // required to fix current css
    .Input--container,
    .Input {
      width: 100%;
    }

    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput,
    .DateInput_input {
      width: 100%;
    }
  }

  &__generate-button-container {
    display: flex;
    align-items: center;
    margin-top: 5px;

    button {
      flex-grow: 1;
    }
  }

  &__combined-form {
    padding: 15px;

    .Input--container,
    .Input {
      max-width: 180px;
    }

    .FormColumn {
      max-width: fit-content;
      padding-right: 30px;
    }

    &--button-container {
      padding: 39px 30px 0 0;
    }
  }
}
