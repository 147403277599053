@import 'styles/colors';
@import 'styles/mixins';

.Checkbox {
  &__input {
    display: none;
  }

  &__title {
    min-width: fit-content;
    padding-left: 18px;
    color: $color-black;

    cursor: pointer;
    &--read-only,
    &--disabled {
      cursor: text;
    }
  }

  &__label {
    &--editable {
      @include checkbox-icon-hover();
    }

    display: flex;
    width: fit-content;
    align-items: center;
  }

  &__icon {
    font-size: 9px;

    color: $color-white;
    &--read-only,
    &--disabled {
      color: $color-gray-4;
    }
  }

  &__icon-container {
    @include transition-all-ease-in-out('border, background-color');
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 14px;
    height: 14px;
    border: solid 1px $color-gray-3;
    border-radius: 4px;
    cursor: pointer;

    &--checked {
      border: solid 1px $color-blue;
      background-color: $color-blue;
    }

    &--read-only,
    &--disabled {
      cursor: default;
      border: solid 1px $color-gray-3;
      background-color: $color-gray-2;
    }
  }
}
