@import 'styles/colors';
@import 'styles/mixins-chart';

.InventoryChart {
  padding: 30px;

  &__loading {
    padding-top: 30px;
  }

  &__empty {
    padding-top: 30px;
  }

  &__filters {
    padding: 25px 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .Select__control {
      min-height: 35px;
      min-width: 200px;
    }

    .InputTitle {
      font-weight: normal;
    }
  }

  &__filters-row {
    align-items: center;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__checkboxes {
    display: flex;
    padding-left: 25px;
    padding-bottom: 20px;

    &--header {
      padding-bottom: 20px;
    }

    &--inOut {
      font-size: 13px;
      padding-bottom: 10px;
    }

    &--container {
      display: flex;
    }

    .Checkbox {
      display: flex;
      padding-right: 35px;

      &__title {
        display: flex;
        align-items: center;
        padding-left: 15px;

        img {
          padding-right: 15px;
        }
      }
    }
  }

  &__container {
    padding-top: 10px;
  }

  &__label {
    font-size: 11px;
    color: $color-gray-4;
    padding-bottom: 5px;
  }

  @include axis-tick();
  @include tooltip();

  &__legend {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 12px;
    color: $color-gray-4;
    padding: 10px 0 0 65px;

    &--item {
      display: flex;
      align-items: center;
      padding-bottom: 15px;

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    &--symbol {
      width: 19px;
      margin-right: 10px;
      border-radius: 2px;
      height: 12px;
    }

    &--reserved {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
    }
  }

  &__legend--symbol {
    &-reserved {
      background-color: $color-purple;
    }

    &-free {
      background-color: $color-gray-2;
    }

    &-prio-3 {
      background-color: #CAF0F8;
    }

    &-prio-4 {
      background-color: #90E0EF;
    }

    &-prio-5 {
      background-color: #00B4D8;
    }

    &-prio-6 {
      background-color: #0077B6;
    }

    &-prio-7 {
      background-color: #04545E;
    }

    &-prio-8 {
      background-color: #02033D;
    }
  }
}
