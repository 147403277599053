@import 'styles/colors';
@import 'styles/mixins';

.Tooltip {
  position: relative;

  &__text {
    @include tooltip-text(12px, 8px);
    top: 10px;
    white-space: nowrap;
  }
}
