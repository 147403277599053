@import 'styles/constants';

.LiveMediaMap {
  $area-padding: 15px;
  min-height: calc(100vh - #{$topbar-height});
  position: sticky !important;
  top: $topbar-height;

  &__active-area {
    position: absolute;
    top: $area-padding;
    right: 50px + $summary-min-width + $area-padding;
    bottom: 30px;
    left: 485px + $area-padding;
  }
}
