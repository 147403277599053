@import 'styles/colors';

.EstimatedAudienceEmissions {
  &__hidden-target {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p:first-of-type {
      font-size: 14px;
      margin-top: 10px;
      color: $color-black;
    }
  }


  &__button {
    margin-top: 11px;
  }
}
