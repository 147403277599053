@import 'styles/colors';
@import 'styles/mixins';

.CampaignActions {
  display: flex;
  position: relative;
  padding: 20px 0;
  z-index: 3;

  .Button {
    margin-right: 40px;
  }
}
