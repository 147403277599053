@import 'styles/colors';
@import 'styles/mixins';

.DatePickerRange {
  $navbar-height: 48px;
  $day-square: 32px;

  box-shadow: none;
  border-radius: 0;
  &-loading {
    .DayPicker {
      &-Day {
        color: $color-gray-2;
      }
    }
  }

  .DayPicker {
    &-wrapper {
      padding: 0;

      &:focus {
        outline: none;
      }

      &:before {
        content: '';
        display: block;
        border-top: 1px solid $color-gray-2;
        position: relative;
        top: calc(#{$navbar-height} + 1px);
      }
    }

    &-Day,
    &-Caption > div {
      font-size: 14px;
    }

    &-Weekday,
    &-Caption > div,
    &-Day--firstDayFromRange,
    &-Day--lastDayFromRange,
    &-Day--today {
      font-weight: 600;
    }

    &-Caption {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      text-transform: capitalize;

      & > div {
        display: flex;
        align-items: center;
        height: $navbar-height;
      }
    }

    &-Weekdays {
      margin: 0;
      padding: 15px 0 6px 0;
    }

    &-Weekday {
      font-size: 10px;
      color: $color-gray-4;
      padding: 0;
    }

    &-Weekday,
    &-Day {
      width: $day-square;
    }

    &-Month {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0 25px;
    }

    &-NavBar {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: $navbar-height;
      padding: 0 25px;
      color: $color-gray-4;

      svg {
        width: 20px;
        cursor: pointer;

        &:hover {
          color: $color-blue;
        }
      }
    }

    &-Week {
      display: flex;
      margin: 9px 0;
    }

    &-Day {
      display: flex;
      justify-content: center;
      align-items: center;
      height: $day-square;
      padding: 0;
      border-radius: 0;
      @include transition-all-ease-in-out('border');

      &:hover {
        background-color: $color-gray-3 !important;
        color: $color-white !important;
        border-radius: 5px;
      }

      &:focus {
        outline: none;
      }

      &--read-only {
        cursor: default;

        &:hover {
          background-color: $color-white !important;
          color: $color-black !important;
        }
      }

      &--selected {
        color: $color-white;
        background-color: $color-blue;
        border-top: 4px solid $color-white;
        border-bottom: 4px solid $color-white;
        filter: opacity(0.8);

        &:hover {
          border-radius: 0px;
        }
      }

      &--first-day-from-range,
      &--last-day-from-range {
        border-top: 0px solid $color-white;
        border-bottom: 0px solid $color-white;
        border-radius: 5px;
        filter: opacity(1);

        &:hover {
          border-radius: 5px;
        }
      }

      &--already-in-range:hover {
        background-color: $color-blue !important;
        color: $color-white !important;

        &:not(.DayPicker-Day--read-only) {
          filter: opacity(1);
        }
      }

      &--disabled {
        color: $color-gray-2 !important;

        &:hover {
          color: $color-gray-2 !important;
          background-color: $color-white !important;
        }

        &.DayPicker-Day--selected,
        &.DayPicker-Day--selected:hover {
          color: $color-white !important;
          background-color: $color-gray-2 !important;
          filter: opacity(1);
        }
      }

      &--outside {
        color: $color-white !important;
        background-color: $color-white !important;

        &:hover {
          color: $color-white !important;
          background-color: $color-white !important;
        }
      }

      &--today,
      &--today:hover {
        color: $color-red !important;
        font-weight: 800 !important;
      }
    }
  }
}
