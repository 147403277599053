@import 'styles/constants';
@import 'styles/colors';
@import 'styles/mixins';

.CampaignForm {
  position: relative;

  &__header {
    height: 62px;
    margin-bottom: 15px;
  }

  &__header,
  &__card {
    @include box-shadow($color-black, 0.1);
    width: 435px;
  }

  &__card--basics {
    z-index: 4 !important; // Because `react-dates` bug. Can't set calendar z-index.
  }

  &__summary-card {
    @include box-shadow($color-black, 0.1);
    padding: 0;
    position: relative;
    min-width: $summary-min-width;

    &--container {
      height: 100%;
      margin: 31px 49px;
      position: fixed;
      right: 0;
      top: $topbar-height;
      z-index: 2;
    }
  }

  .PageHeader,
  .CampaignForm__card {
    position: relative;
    z-index: 3;
  }

  &__live-media-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
