.Roles {
  &__data {
    &--name {
      font-weight: 600;
    }

    &--buttons {
      gap: 19px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: inherit;
    }
  }

  &__form {
    &--row {
      padding: 25px 0;
    }
    padding: 30px;
    &--loading,
    &--buttons {
      width: 340px;
    }

    &--loading {
      min-height: 248.4px;
    }

    &--buttons {
      display: flex;
      padding-top: 50px;

      .Button:first-of-type {
        margin-right: 27px;
      }
    }
  }

  &__privileges {
    &--title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 0;
    }
    &--card {
      width: 375px;
      padding-bottom: 10px;
      &__list {
        .item {
          display: flex;
          justify-content: space-between;
          padding: 5px 0  ;
        }
      }
      &__header {
        height: 26px;
        display: flex;
        align-items: baseline;
        padding: 0;
        background: white;
        .CardHeader__heading {
          font-size: 14px;
        }
      }
      &--with-switch {
        span {
          font-weight: 600;
        }
        display: flex;
        justify-content: space-between;
      }
    }

  }
}

