@import 'styles/colors';
$input-width: 200px;
$spacing: 15px;

.DatePickerRangeSeparate {
  $input-height: 35px;

  .DateInput_input {
    width: $input-width;
    height: $input-height;
  }

  &__filter {
    display: inherit;
    gap: inherit;
    flex-direction: column;
  }

  &__input-row {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__title-row {
    .InputTitle {
      width: $input-width;
    }

    gap: $spacing;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__divider {
    width: 8px;
    margin: 0 3px;
    height: 1px;
    background-color: $color-gray-3;
  }
}