@import 'styles/colors';
@import 'styles/mixins';

.ModalUpload {
  @include transition-all-ease-in-out('border');
  @extend .ModalUpload__container;

  &__loading-container {
    @extend .ModalUpload__container;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    width: 516px;
    height: 252px;
    border: 2px dashed $color-gray-3;
    border-radius: 4px;
  }

  &:focus,
  &:hover {
    border-color: $color-blue;
    background-color: $color-gray-1;
    outline: none;
    cursor: pointer;

    .ModalUpload__body--cloud {
      background-image: url('~images/upload-cloud-blue.svg');
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--cloud {
      @include transition-all-ease-in-out('background-image');
      background-image: url('~images/upload-cloud-gray.svg');
      width: 62.5px;
      height: 56.7px;
      margin-bottom: 18px;
    }

    &--drop {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &--frame {
      width: 240px;
      height: 37px;
      line-height: 37px;
      background-color: $color-gray-2;
      border-radius: 6px;
      font-size: 14px;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  &__frame {
    &--or,
    &--select {
      color: $color-black;
    }

    &--browse {
      font-weight: 600;
      color: $color-blue;
    }
  }
}
