@import 'styles/mixins';
@import 'styles/constants';

.CampaignDetails {
  &__header {
    &--container {
      flex-direction: column;
    }

    .PageHeader__heading {
      justify-content: space-between;

      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
        gap: 20px;
      }
    }

    &--second {
      display: flex;
      margin-bottom: 20px;
      font-weight: 600;

      .Button {
        margin-left: 20px;
      }
    }

    &--buttons {
      display: flex;

      .Button {
        margin-left: 15px;

        @media (max-width: $breakpoint-sm) {
          margin: 0 15px;
        }
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    font-size: 20px;

    &--icon {
      margin-right: 6px;
    }

    &--purple {
      color: $color-purple;
    }

    &--yellow {
      color: $color-yellow;
    }

    &--green {
      color: $color-green;
    }

    &--green-dark {
      color: $color-green-dark;
    }

    &--orange {
      color: $color-orange;
    }

    &--red {
      color: $color-red;
    }
    &--blue {
      color: $color-navy-blue;
    }
    &--reservation {
      color: $color-blue;
      padding-left: 10px;
    }
  }

  &__map {
    margin-top: 11px;

    &--card {
      @include avoid-break-inside-while-print();
    }
  }

  &__media-list {
    width: 100%;
    padding: 15px;
  }

  &__no-creations-container {
    padding: 25px 15px;
  }

  &__pdf-spinner {
    transform: scale(0.5);
  }
}
