@import '~leaflet/dist/leaflet.css';
@import 'styles/colors';
@import 'styles/mixins';

.Map {
  min-height: 500px;
  background-color: $color-gray-1;

  .leaflet {
    &-bottom {
      .leaflet-control-zoom {
        bottom: 35px;
      }
    }

    &-control {
      font-size: 10px;
      background-color: $color-gray-2;
      border-radius: 0px 6px 0px 0px;
      border-color: $color-gray-2;

      a {
        color: $color-black;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    &-popup-content {
      width: fit-content !important;
      min-width: 200px;
      margin: 10px;
    }
  }

  &__cluster {
    background-color: $color-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.16);

    &:hover {
      .Map__cluster--circle {
        background-color: $color-blue;
      }

      .Map__cluster--text {
        color: $color-white;
      }
    }

    &--circle {
      width: 32px;
      height: 32px;
      background-color: $color-white;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--text {
      font-size: 12px;
      font-weight: 600;
      color: $color-blue;
    }
  }

  a.leaflet-popup-close-button {
    top: 5px;
    right: 5px;

    color: $color-gray-3;

    &:hover {
      color: $color-red;
    }
  }

  &__info {
    @include box-shadow($color-black, 0.1);

    bottom: 0;
    right: 0;
    z-index: 401;
    position: absolute;
    background-color: $color-gray-2;
    padding: 6px;
    border-radius: 6px 0px 0px 0px;
    font-size: 12px;

    &--key {
      color: $color-blue;
      font-weight: 600;
    }

    &--hidden {
      opacity: 0;
    }
  }
}
