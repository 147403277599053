@import '../../../../../styles/colors';

.CreationsFormEmissionNumber {
  $preview-col-padding-right: 15px;
  $max-thumbnail-width: 94px;
  $max-thumbnail-height: 77px;
  @mixin tooltip-colored-content($colour){
    .TooltipHelp__text {
      border-color:$colour;
      border-left: 15px solid $colour;
    }
    border: 1px solid $colour;
    .TooltipHelp__icon {
      color: $colour;
    }
  }

  &--preview {
    max-height: 77px;
    max-width: 94px;
    height: 77px;
  }

  &__emissions {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    gap: 10px;
    &--number {
      color: $color-blue;
    }
    &--label, &--alert {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &--tooltip-warning {
      @include tooltip-colored-content($color-yellow);
    }
    &--tooltip-alert {
      @include tooltip-colored-content($color-red);
    }
    &--tooltip-info {
      border: 1px solid $color-gray-4;
      .TooltipHelp__icon {
        color: $color-gray-4;
      }
    }
  }

  &--row {
    padding: 0 0 15px 30px;
    align-items: center;
    .FlexCol {
      &:nth-child(1) {
        max-width: $max-thumbnail-width + $preview-col-padding-right;
      }

      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        margin-right: $preview-col-padding-right;
      }

      &:nth-child(3) {
        max-width: min-content;
        display: flex;
        justify-content: flex-end;
        margin-right: $preview-col-padding-right * 2;
      }
    }
  }
}
