.CreationsFormUpload {
  &__requirements {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 12px;
  }

  &__header {
    display: flex;
    align-self: flex-start;

    &--container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 25px;
    }

    &--img {
      padding-right: 20px;
    }

    &--title {
      align-self: center;
      font-size: 24px;
      font-weight: 600;
    }
  }

  &__accepted-formats {
    padding-top: 10px;
  }

  &--title-bold {
    font-weight: 600;
  }

  &__zip-info {
    margin-top: 10px;
  }

  &__option {
    text-align: end;

    span {
      font-weight: 600;
    }
  }
}
