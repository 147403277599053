@import 'styles/constants';

.DetailsFormMediaFormat {
  &__row {
    display: flex;
  }

  &__radio-button {
    padding: 10px 15px 0 0;
  }
}
