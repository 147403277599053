@import 'styles/colors';

.Pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;

  &__item {
    &--disabled {
      opacity: 0.5;

      .Pagination__link {
        cursor: default;
      }
    }

    &--active {
      border-radius: 5px;
      background-color: $color-navy-blue;
    }
  }

  &__link {
    display: flex;
    width: 30px;
    height: 25px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    color: $color-black;
    text-decoration: none;

    &--active {
      color: $color-white;
    }

    &--first,
    &--prev,
    &--next,
    &--last {
      color: $color-navy-blue;
    }
  }
}
