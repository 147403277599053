@import 'styles/colors';
@import 'styles/constants';

.rc-switch {
  &--small {
    transform: scale(0.8);
  }
  margin-right: -5px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 22px;
  line-height: 20px;
  padding: 0;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  transition: all .3s cubic-bezier(0.35,0,0.25,1);
  overflow: hidden;
}
.rc-switch-inner-checked, .rc-switch-inner-unchecked {
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 0;
  transition: left .3s cubic-bezier(0.35,0,0.25,1);
}
.rc-switch-inner-checked {
  left: -14px;
}
.rc-switch-inner-unchecked {
  left: 24px;
}
.rc-switch:after {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 2px;
  top: 1px;
  border-radius: 50% 50%;
  background-color: $color-gray-1;
  content: " ";
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.26);
  transform: scale(1);
  transition: left .3s cubic-bezier(0.35,0,0.25,1);
  animation-timing-function: cubic-bezier(0.35,0,0.25,1);
  animation-duration: .3s;
  animation-name: rcSwitchOff;
}
.rc-switch:focus {
  box-shadow: 0 0 0 2px tint(#2db7f5,80%);
  outline: none;
}
.rc-switch-checked {
  border: 1px solid $color-blue;
  background-color: $color-blue;
}
.rc-switch-checked .rc-switch-inner-checked {
  left: 6px;
}
.rc-switch-checked .rc-switch-inner-unchecked {
  left: 44px;
}
.rc-switch-checked:after {
  left: 22px;
}
.rc-switch-disabled {
  cursor: no-drop;
  background: #ccc;
  border-color: #ccc;
}
.rc-switch-disabled:after {
  background: #9e9e9e;
  animation-name: none;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  transform: scale(1);
  animation-name: none;
}
.rc-switch-label {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  user-select: text;
}

