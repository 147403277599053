@import 'styles/colors';


.Agencies {
  &__container {
    max-width: 80%;
    gap: 20px;
  }

  &--readonly {
    color: $color-gray-4;

    input {
      cursor: not-allowed;
      color: $color-gray-4;
    }
  }

  &__data {
    &--name {
      font-weight: 600;
    }

    &--buttons {
      gap: 19px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: inherit;
    }
  }

  &__form {
    padding: 30px;

    &__header {
      font-weight: 600;
      font-size: 14px;
      align-self: flex-start;
    }

    &--row {
      padding: 15px 0;
      width: 375px;
    }

    &--loading,
    &--buttons {
      width: 340px;
    }

    &--loading {
      min-height: 248.4px;
    }

    &--buttons {
      display: flex;
      padding-top: 50px;

      .Button:first-of-type {
        margin-right: 27px;
      }
    }

    &--targets-container {
      display: flex;
      padding-top: 30px;
      gap: 20px;
    }

    &--mediums-container {
      padding-top: 20px;
      display: grid;
      grid-template-columns: 120px auto 100px;
      grid-gap: 10px;
      align-items: end;
      font-size: 12px;

      .Input--container {
        width: auto;
      }

      .InputTitle {
        font-size: 12px;
      }

      .Button--standard {
        min-width: auto;
        height: 50px;
      }
    }

    &--media-list {
      max-width: 600px;

      .Table__container {
        line-height: 24px;
      }

      .Button--standard {
        margin: 3px 0;
      }
    }

    &--medium-usage-input {
      width: auto;
    }
  }
}
