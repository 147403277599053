@import 'styles/colors';

.FullScreenSpinner {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon-container {
    display: flex;
    color: $color-blue;
    font-size: 44px;
  }
}
