@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/mixins-chart';

.CampaignDetailsChartFilters {
  gap: 30px;
  padding: 0 15px 30px 15px;
  flex-wrap: wrap;

  .InputTitle {
    font-weight: 400;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 30px;
    flex-grow: 1;

    & > div {
      flex-grow: 1;
    }
  }
}
