@import 'styles/colors';
@import 'styles/constants';

.AlertsCard {
  &--info .Summary__header, &--warn .Summary__header {
    height: 42px;
  }

  &--info {
    border-color: #{$color-yellow};

    .Summary__header {
      border-color: #{$color-yellow};
    }
  }

  &--warn {
    border-color: #{$color-red};

    .Summary__header {
      border-color: #{$color-red};
    }
  }

  &__body {
    font-size: 14px;
    color: $color-black;
    font-weight: 400;
    padding-bottom: 11px;

    &--number {
      font-weight: 600;
    }
  }

  &__modal {
    &--content {
      width: 700px;
      max-height: $modal-max-height;
      height: fit-content;

      .Alerts__header {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 10px 0;
      }
      .Alerts__alert {
        padding: 0 0 5px 10px;
      }
    }
  }
}
