@import 'styles/colors';
@import 'styles/helpers';
@import 'styles/mixins';

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans400.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans600.ttf') format('truetype');
  font-weight: 600;
}

html {
  @include scrollbar(12px);
  overflow-y: scroll;
}

html,
body,
h1,
h2,
p {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  color: $color-black;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

div,
a {
  box-sizing: border-box;
}

h1 {
  font-size: 24px;
}

h1,
b {
  font-weight: 600;
}

h2 {
  font-size: 18px;
  font-weight: 400;
}
