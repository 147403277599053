@import 'styles/colors';

.Link {
  display: inline;
  color: $color-blue;
  cursor: pointer;

  &:focus,
  &:hover {
    color: $color-navy-blue;
  }

  &--disabled {
    color: $color-gray-3;
    cursor: default;

    &:focus,
    &:hover {
      color: $color-gray-3;
    }
  }
}
