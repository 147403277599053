@import 'styles/colors';

.IconAlert {
  width: 24px;
  height: 24px;
  &--info {
    color: $color-yellow;
  }
  &--warn {
    color: $color-red;
  }
}
