@import 'styles/colors';
@import 'styles/constants';

.ModalAction {
  min-width: $modal-min-width;

  &__text {
    color: $color-gray-4;

    &--content {
      padding-bottom: 20px;
    }

    &--name {
      font-weight: 600;
      color: $color-black;
    }
  }

  &__text-input {
    padding-top: 25px;
  }

  &__text-input, &__text-input-display {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    gap: 10px;

    &-title {
      font-weight: 600;
      color: $color-black;
    }
  }
}
