@import 'styles/colors';
@import 'styles/mixins';

.TooltipHelp {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: solid 1px $color-gray-4;
  cursor: help;

  &__icon {
    color: $color-gray-4;
    font-size: 8px;
  }

  &__text {
    @include tooltip-text(14px, 15px);
    width: 320px;
    left: 16px;
  }
}
