@import 'styles/constants';

.Reservation {
  min-width: $modal-min-width;

  .DayPicker {
    padding-top: 20px;
    border: none;
  }

  .ModalFooterContainer {
    margin: 0 -30px -20px -30px;
    margin-top: 20px;
  }
}
