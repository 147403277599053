.BasicsForm {
  padding-left: 15px;

  &__row {
    padding-top: 35px;

    &:last-of-type {
      padding-bottom: 35px;
    }
  }

  &__date-input {
    &--column {
      &:first-of-type {
        padding-right: 24px;
      }
    }
  }
}
