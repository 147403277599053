@import 'styles/colors';
@import 'styles/mixins-chart';

.InventoryTopTenGalleries {
  &__spinner-container {
    min-height: 407px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 50px;
  }

  &__filters {
    display: flex;
    padding: 10px;
    gap: 20px;

    & > div {
      flex-grow: 1;
    }

    // required to fix current css
    .Input--container,
    .Input {
      width: 100%;
    }

    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput,
    .DateInput_input {
      width: 100%;
    }

    .DateInput_input {
      height: 35px;
    }
  }
}
