@import 'styles/mixins';

.EmissionPlan {
  overflow: hidden;
  width: calc(100vw - 340px);
  height: 100%;

  @media (max-width: 1020px) {
    width: calc(100vw - 284px);
  }

  &--full-width {
    width: 100%;

    @media (max-width: 1020px) {
      width: 100%;
    }
  }

  &__status {
    margin-bottom: 20px;
  }

  &__loading-spinner {
    padding: 10px 0 20px 0;
  }
}
