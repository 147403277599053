@import 'styles/mixins';

@mixin dropdown-indicator() {
  &__dropdown-indicator {
    color: $color-gray-4 !important;
    padding: 0 10px 0 0 !important;
    cursor: pointer;

    &:hover {
      color: $color-blue !important;
    }
  }
}

@mixin clear-indicator() {
  &__clear-indicator {
    color: $color-gray-4 !important;
    cursor: pointer;

    &:hover {
      color: $color-blue !important;
    }
  }
}

@mixin input() {
  &__input {
    color: $color-gray-5;
  }
}

@mixin menu() {
  &__menu {
    margin-top: 1px !important;
    border: solid 1px $color-gray-3;
    border-radius: 2px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;

    &-list {
      @include scrollbar();
    }
  }
}

@mixin control() {
  transition: border-color 0.1s ease-in-out !important;
  cursor: text !important;
  border-color: $color-gray-3 !important;
  border-radius: 6px !important;

  &--is-focused {
    border: solid 1px $color-blue !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &--is-disabled {
    background-color: $color-gray-2 !important;
  }

  &:hover {
    border-color: $color-blue !important;
  }
}

@mixin single-value() {
  &__single-value {
    margin: 0 !important;

    &--is-disabled {
      color: $color-black !important;
    }
  }
}

@mixin option() {
  &__option {
    color: $color-gray-5 !important;
    cursor: pointer !important;

    &--is-disabled {
      background-color: $color-gray-2 !important;
    }

    &--is-focused {
      background-color: $color-gray-1 !important;
    }

    &--is-selected {
      background-color: $color-blue !important;
      color: $color-white !important;
    }
  }
}

@mixin value-container() {
  &__value-container {
    padding: 2px 12px !important;
    color: $color-gray-4;
  }
}

@mixin placeholder() {
  &__placeholder {
    color: $color-gray-3 !important;
    margin-left: 0 !important;
  }
}

@mixin error($component) {
  &__error {
    .#{$component}__control {
      border: solid 1px $color-red !important;

      &--is-focused {
        border: solid 1px $color-blue !important;
      }
    }
  }
}
