@import 'styles/colors';


.NoteModal {
  .Modal__content {
    width: 618px;
  }

  &__input {
    box-sizing: border-box;
    max-width: 474px;
    min-height: 80px;
    padding: 5px 10px;
    width: 100%;
    margin: 20px 30px;
  }

  &__display {
    max-height: 300px;
    overflow-y: auto;
    p {
      padding: 20px 30px;
    }
    &--contractNote {
      border-top: 1px solid $color-gray-2;
    }
  }
  &__children {
    padding: 0;
  }
}
