@import 'styles/colors';

.Summary {
  display: flex;
  justify-content: center;

  &,
  &__header {
    padding: 11px 15px;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $color-gray-2;

    &--title {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__quantity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: $color-blue;

    &--with-circle {
      width: 90px;
      height: 90px;
      border: 15px solid $color-blue;
      border-radius: 50%;
    }
  }
}
