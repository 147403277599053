@import 'styles/colors';
@import 'styles/constants';

.MediaSelectionModal {
  width: 800px;
  height: calc($modal-max-height + 120px) ;
  max-height: calc($modal-max-height + 120px);

  &__footer {
    position: relative;
    padding: 10px 0;

    .Checkbox__title {
      padding-left: 7px;
    }

    &--error {
      position: absolute;
      top: -16px;
    }
  }

  &--dg-in {
    width: 900px;
  }

  &__template {
    &-footer {
      display: flex;
      gap: 10px;
    }

    &-label {
      display: flex;
      justify-content: space-between;
    }
  }
}
.Select__option.Select__option--is-selected {
  .Link {
    color: white;
  }
}
