$topbar-height: 80px;
$slider-width: 375px;
$layout-content-padding: 50px;
$card-max-width: 1820px;
$card-margin-bottom: 15px;
$summary-min-width: 200px;
$modal-min-width: 618px;
$modal-max-height: 400px;
$modal-z-index: 1020;
$card-min-width: 880px;
$breakpoint-l: 1020px;
$breakpoint-sm: 576px;
