@import 'styles/colors';

.IconBox {
  height: 35px;
  min-width: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: $color-white;
  font-size: 16px;

  &--purple {
    background-color: $color-purple;
  }
  &--yellow {
    background-color: $color-yellow;
  }
  &--green {
    background-color: $color-green;
  }
  &--green-dark {
    background-color: $color-green-dark;
  }
  &--orange {
    background-color: $color-orange;
  }
  &--red {
    background-color: $color-red;
  }
  &--blue {
    background-color: $color-navy-blue;
  }
}
