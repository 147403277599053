@import 'styles/colors';
@import 'styles/constants';

.FillingSpinner {
  display: flex;

  &--full-container,
  &--full-layout-content {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &--full-container {
    height: 100%;
  }

  &--full-layout-content {
    height: calc(100vh - #{$topbar-height} - 2 * #{$layout-content-padding});
  }

  &--standard {
    justify-content: center;
  }

  &__icon {
    color: $color-blue;

    &--full-container,
    &--full-layout-content {
      font-size: 44px;
    }

    &--standard {
      font-size: 24px;
    }

    &--blue {
      color: $color-blue;
    }

    &--white {
      color: $color-white;
    }

    &--green {
      color: $color-green;
    }
  }
}
