@import 'styles/colors';
@import 'styles/constants';

.Sign {
  display: flex;
  justify-content: space-between;

  &__left {
    width: 375px;

    &--container {
      display: flex;
      width: 40%;
      flex-direction: column;
      align-items: center;
      align-self: flex-start;
      margin: 52px 0;

      @media only screen and (min-width: 1920px) {
        width: 50%;
      }
    }
  }

  &__logo {
    width: 80px;

    &:hover {
      cursor: pointer;
    }
  }

  &__header {
    &--first {
      padding-top: 80px;
    }

    &--second {
      padding: 10px 0 47px 0;
    }
  }

  &__form {
    .Input--container {
      &:nth-of-type(1) {
        margin-bottom: 20px;
      }

      &:nth-of-type(2) {
        margin-bottom: 6px;
      }
    }

    &--button {
      margin-top: 47px;
    }
  }

  &__right--container {
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
    margin-top: 52px;

    @media only screen and (min-width: 1920px) {
      width: 50%;
      justify-content: flex-start;
    }
  }
}
