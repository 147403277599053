@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/constants';

.Navbar {
  margin-top: 100px;

  &__container {
    position: sticky;
    top: $topbar-height;
    min-width: 220px;
    height: 100%;
    min-height: calc(100vh - #{$topbar-height});
    background: $color-white;
    border-right: 1px solid $color-gray-2;
    z-index: 9;
  }


  &__version {
    position: absolute;
    bottom: 16px;
    left: 19.5px;
    color: $color-gray-4;
    font-weight: 600;
  }
}
