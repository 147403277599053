@import 'styles/colors';
@import 'styles/mixins';

.CreationAlert.TooltipHelp {
  border: solid 1px $color-red;

  &__icon {
    color: $color-red;
    transform: translateY(1px);
  }
}

.CreationAlert {
  &__text {
    display: flex;
    gap: 10px;
  }

  .TooltipHelp__icon {
    color: $color-red;
  }

  .TooltipHelp__text {
    width: 600px;
    border: 2px solid $color-red;;
  }
}

.AlertContent {
  padding: 10px;
  display: flex;
  justify-content: center;

  &__icon {
    margin-right: 20px;
  }

  &__container {
    width: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
  }

  &__solution {
    font-weight: 600;
    margin-top: 10px;
  }
}

.CreationSchedules.TooltipHelp {
  margin-left: 5px;

  &__icon {
    transform: translateY(1px);
  }

  .TooltipHelp__text {
    width: 280px;
  }
}

.CreationSchedules {
  &__details {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__header {
    display: flex;
    align-items: center;
  }
}

.CreationsFormPreview {
  $preview-col-padding-right: 15px;
  $max-thumbnail-width: 94px;
  $max-thumbnail-height: 77px;

  .CreationsFormPreview__creation--details .CreationsFormPreview__target-container {
    display: flex;
    align-items: center;
    gap: 10px;
    .TooltipHelp {
      margin-top: 25px;
    }
    .Input--container {
      width: 170px;
    }
  }


  &--grayed-out {
    opacity: 0.5;
  }

  &__contains-alcohol {
    padding: 15px 0 10px 0;
  }

  &__bar {
    display: flex;
    align-items: center;
    height: 46px;
    background-color: $color-gray-1;
    position: relative;
    padding-left: 30px;

    &--icon {
      height: 34px;
    }

    &--title {
      padding-left: 15px;
      font-size: 16px;
      font-weight: 600;
    }

    &--add {
      margin-left: 15px;
    }
  }

  &__creation {
    padding-top: 15px;

    &__text {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &--alert {
      color: $color-red;
    }

    &--tooltip {
      position: absolute;
      left: 8px;
    }

    &--preview {
      max-height: 77px;
      max-width: 94px;
      height: 77px;
    }

    &--empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      padding-bottom: 15px;
    }

    &--name {
      font-weight: 600;
      word-break: break-all;
    }

    &--as-id, &--details {
      padding-top: 10px;

      .Input--container {
        padding: 5px 0;
      }

      .Input--container,
      .Input {
        width: 200px;

        input {
          min-height: 35px;
        }
      }

      .Input.CreationsFormPreview__target {
        width: 170px;
      }
    }

    &-details {
      &,
      &--archived {
        font-weight: 600;
      }

      &--container {
        display: flex;
        padding-top: 10px;
      }

      &--date {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 198px;
      }

      &--archived {
        padding-left: 15px;
      }

      &--pending-acceptance {
        padding: 5px 0;
      }
    }

    &--row {
      padding: 0 0 15px 30px;

      .FlexCol {
        &:nth-child(1) {
          max-width: $max-thumbnail-width + $preview-col-padding-right;
        }

        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          margin-right: $preview-col-padding-right;
        }

        &:nth-child(3) {
          max-width: min-content;
          display: flex;
          justify-content: flex-end;
          margin-right: $preview-col-padding-right * 2;
        }
      }
    }
  }

  &__duration, &__duration--disabled {
    padding: 10px 0;
    width: auto;

    .Slider__handle::after {
      content: "s"
    }

    &--disabled {
      .Slider__handle {
        cursor: default;
        color: $color-gray-4;
      }

      .Slider__handle--indicator {
        cursor: default;
      }

      .ProgressBarIndicator--background {
        background-color: $color-gray-4;
      }

      .ProgressBarIndicator--circle {
        border-color: $color-gray-4;
      }

      .rc-slider-track {
        // override inline style
        background-color: $color-gray-4 !important;
      }
    }
  }

  &__button--tooltip {
    position: absolute;
    right: 8px;
  }

  &__button--send-to-acceptance {
    margin: 24px 0 16px 29px;
  }
}
