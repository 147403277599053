@import 'styles/colors';

.ProgressBar {
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 6px;
  background-color: $color-gray-1;

  &__container {
    display: flex;
    align-items: center;
    height: 22px;
  }

  &__progress {
    height: inherit;
    border-radius: inherit;
    background-color: $color-blue;
  }
}
