.EmissionSchedule {
  &__header {
    display: flex;
    align-items: center;
  }

  &__tooltip {
    width: 650px;

    &--a {
      margin: 0 0 20px 22px;
    }

    &--b {
      margin-left: 22px;
    }
  }

  &__schedule,
  &__emission-number,
  &__locations {
    display: block;
    padding: 15px;
  }

  &__locations--hidden {
    display: none;
  }

  &__button {
    margin-top: 25px;
  }

  &__map {
    margin-top: 11px;
  }
}
