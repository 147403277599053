@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/constants';

.CampaignDetailsCreations {
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;

  &--grayed-out {
    opacity: 0.5;
  }

  &__col {
    min-width: 50%;
    max-width: 50%;

    @media (max-width: $breakpoint-l) {
      min-width: 300px;
      max-width: 100%;
    }

    &:nth-child(odd) {
      .CampaignDetailsCreations__header {
        margin-right: 15px;
      }

      .CampaignDetailsCreations__container {
        margin: 0 30px;
      }

      @media (max-width: $breakpoint-l) {
        .CampaignDetailsCreations__header {
          margin: 0 15px;
        }

        .CampaignDetailsCreations__container {
          margin: 15px
        }
      }
    }

    &:nth-child(even) {
      .CampaignDetailsCreations__container {
        margin: 0 30px 0 45px;
      }

      .CampaignDetailsCreations__header {
        margin-left: 15px;
      }

      @media (max-width: $breakpoint-l) {
        .CampaignDetailsCreations__container {
          margin: 15px;
        }

        .CampaignDetailsCreations__header {
          margin: 0 15px;
        }
      }
    }
  }

  &__header {
    @include avoid-break-inside-while-print();
    display: flex;
    align-items: center;
    height: 51px;
    margin-bottom: 18px;
    background-color: $color-gray-1;

    &--icon,
    &--format {
      position: relative;
    }

    &--icon {
      left: 30px;
      height: 34px;
    }

    &--format {
      font-size: 18px;
      font-weight: 600;
      left: 60px;

      @media (max-width: $breakpoint-l) {
        font-size: 16px;
        left: 40px;
      }
    }
  }

  &__container {
    @include avoid-break-inside-while-print();
    display: flex;
    padding-bottom: 18px;
  }

  &__item {
    &--info {
      display: flex;
      flex-direction: column;
    }

    &--preview {
      height: 319px;
      max-height: 77px;
      min-width: 100px;
      max-width: 100px;
      margin-right: 5px;

      &-container {
        position: relative;
      }

      &-tooltip {
        position: absolute;
        left: -24px;
      }
    }

    &--name {
      font-weight: 600;
      word-break: break-all;
    }

    &-details {
      &,
      &--archived {
        font-weight: 600;
      }

      &--container {
        display: flex;
        padding-top: 10px;
      }

      &--date {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 198px;
      }

      &--date {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 235px;
      }

      &--archived {
        padding-left: 15px;
      }
    }
  }

  &__as-id, &__creations-details {
    padding-top: 10px;
  }

  &__empty {
    font-weight: 600;
  }

  &__button {
    margin: 30px 0 15px 30px;
  }
}
