@import 'styles/constants';
@import 'styles/colors';

@mixin common-table() {
  .CommonTable {
    padding-bottom: 20px;

    &__container {
      max-width: $card-max-width;
    }

    &__header-item {
      padding-bottom: 20px;

      &:first-child {
        padding-left: 28px;
      }

      &:last-child {
        padding-right: 28px;
      }
    }

    &__data-row {
      height: 54px;
      cursor: pointer;

      &:hover .CommonTable__data-item {
        background-color: $color-gray-1;
      }

      &:first-child .CommonTable__data-item {
        border-width: 1px 0 0 0;
        border-style: solid;

        &:first-child {
          border-width: 1px 0 0 1px;
          border-radius: 6px 0 0 0;
        }

        &:last-child {
          border-width: 1px 1px 0 0;
          border-radius: 0 6px 0 0;
        }
      }

      &:last-child .CommonTable__data-item {
        border-width: 0 0 1px 0;
        border-style: solid;

        &:first-child {
          border-width: 0 0 1px 1px;
          border-radius: 0 0 0 6px;
        }

        &:last-child {
          border-width: 0 1px 1px 0;
          border-radius: 0 0 6px 0;
        }
      }

      &:only-child .CommonTable__data-item {
        border-width: 1px 0 1px 0;
        border-style: solid;

        &:first-child {
          border-width: 1px 0 1px 1px;
          border-radius: 6px 0 0 6px;
        }

        &:last-child {
          border-width: 1px 1px 1px 0;
          border-radius: 0 6px 6px 0;
        }
      }
    }

    &__data-item {
      white-space: nowrap;
      border-color: $color-gray-2;

      padding-right: 5px;
      @media only screen and (min-width: 1920px) {
        padding-right: 80px;
      }

      &:nth-child(1) {
        width: 100%;
        word-break: break-all;
        white-space: normal;
      }

      &:first-child {
        border-width: 0 0 0 1px;
        border-style: solid;
        padding-left: 28px;
      }

      &:last-child {
        border-width: 0 1px 0 0;
        border-style: solid;
        padding-right: 28px;
      }

      &--name {
        font-weight: 600;
      }

      &--buttons {
        display: flex;
        align-items: center;
        height: inherit;

        button:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }
}
