.InventoryOccupancyChart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  &__wheel {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-image: conic-gradient(
                    #90E0EF 0%,
                    #2A77B6 60%,
                    #E5E6EB 60%,
                    #E5E6EB 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    margin-bottom: 20px;

    &::before {
      content: '';
      width: 110px;
      height: 110px;
      border-radius: 50%;
      background: white;
    }
  }

  &__value {
    position: absolute;
    font-size: 24px;
    font-weight: 600;
    color: #909498;
  }

  &__description {
    font-size: 14px;
    color: #909498;
  }
}
