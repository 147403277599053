@import 'styles/colors';

@mixin box-shadow($color, $alpha: 0.8) {
  box-shadow: 0px 10px 25px rgba($color, $alpha);
}

@mixin transition-all-ease-in-out($properties) {
  transition: all 0.1s ease-in-out;
  transition-property: #{$properties};
}

@mixin fade-in-animation($time: 0.5s) {
  animation: fadeIn $time ease-in;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin collapse-icon($parentName) {
  &__collapse {
    &-btn {
      @include box-shadow($color-gray-3);
      width: 21px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $color-white;
      z-index: 2;
      cursor: pointer;

      &:hover {
        background-color: $color-blue;

        .#{$parentName}__collapse-icon {
          color: $color-white;
        }
      }
    }

    &-icon {
      transition: left 0.2s ease, transform 0.5s ease;
      color: $color-black;

      &--collapsed {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin checkbox-icon-hover() {
  &:hover {
    .Checkbox__icon-container {
      border: solid 1px $color-navy-blue;
    }

    .Checkbox__icon-container--checked {
      background-color: $color-navy-blue;
    }
  }
}

@mixin scrollbar($size: 9px) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background: $color-gray-2;
    border-radius: calc(#{$size} / 2);
    margin: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-gray-3;
    border-radius: calc(#{$size} / 2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-gray-4;
  }
}

@mixin avoid-break-inside-while-print() {
  break-inside: avoid;
}

@mixin tooltip-text($font-size, $padding) {
  @include box-shadow($color-black, 0.16);
  @include fade-in-animation(0.2s);
  position: absolute;
  top: 0px;
  border: solid 1px $color-gray-2;
  border-radius: 6px;
  color: $color-black;
  background-color: $color-white;
  padding: $padding;
  font-size: $font-size;
  font-weight: 400;
  z-index: 5;
}


@mixin table-search-row() {
  $spacing: 15px;
  $input-width: 200px;
  margin-bottom: 15px;
  margin-top: -55px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .Button {
    margin-right: $spacing;
  }
}
