@import 'styles/colors';

.InventoryProgressBar {
  border-bottom: 1px solid $color-gray-2;
  padding: 30px;
  height: 150px;

  &__container {
    width: 760px;
    color: $color-gray-4;
    font-size: 12px;

    &--last-update {
      padding-bottom: 10px;
    }
  }

  &__quantities {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
  }
}
