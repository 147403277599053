@import 'styles/colors';

.ModalFooterContainer {
  padding: 20px 30px;
  background-color: $color-gray-1;
  border-top: solid 1px $color-gray-2;
  border-radius: 0 0 6px 6px;
}

.ModalActionFooter {
  &__spinner {
    height: 40px;
  }

  &__buttons {
    display: flex;

    :first-child {
      margin-right: 25px;
    }
  }
}
