@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/mixins-react-select';

.SelectMultiCheckbox {
  @include dropdown-indicator();
  @include input();
  @include menu();
  caret-color: $color-gray-5;

  &--menu-placement-RIGHT {
    .SelectMultiCheckbox__menu {
      right: 0;
    }
  }

  &__option {
    cursor: pointer !important;

    &:hover {
      @include checkbox-icon-hover();
    }

    &--is-disabled {
      background-color: $color-gray-2 !important;
      color: $color-black !important;
    }

    &--is-focused {
      background-color: $color-gray-1 !important;
    }

    &--is-selected {
      background-color: $color-white !important;
      color: $color-black !important;

      &:hover {
        background-color: $color-gray-1 !important;
      }
    }
  }

  &__control,
  &__menu {
    min-width: 200px !important;
    width: max-content !important;
  }

  &__control {
    @include control();
    height: 35px !important;
    min-height: 35px !important;
  }

  &__value-container {
    padding: 0 12px !important;
    color: $color-gray-3;

    .css-b8ldur-Input {
      margin-left: 5px;
    }
  }

  &__menu {
    z-index: 2 !important;
  }
}
