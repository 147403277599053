@import 'styles/colors';

.SignIn {
  &__link {
    display: flex;
    justify-content: flex-end;
    color: $color-gray-5;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;

    &:focus,
    &:hover {
      color: $color-blue;
    }
  }

  &__header {
    padding: 80px 0 47px 0;
  }

  &__privacy_policy {
    display: flex;
    margin-top: 60px;
  }
}
