.Pricing_Report {
  &__container {
    padding: 15px;
    overflow: auto;
  }

  &__info {
    margin: 10px 0 20px 0;
  }

  &__header {
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  &__row {
    height: 45px;
  }

  &__report-type {
    margin-bottom: 10px;
  }
}
