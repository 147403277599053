@import 'styles/colors';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

.image-gallery {
  min-width: 250px;
  max-width: 350px;
}

.bottom.thumbnails-swipe-horizontal {
   nav {
     overflow-x: auto;
   }
}

.image-gallery-thumbnail {
  &.active {
    border: 4px solid white;
  }

  &:focus {
    border: 4px solid white;
  }

  &:hover {
    cursor: pointer;
    border: 4px solid white;
  }
}
