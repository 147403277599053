@import 'styles/mixins';
@import 'styles/constants';

.CampaignDetailsMediaList {
  &__report {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 20px 0;

    Button {
      min-width: 300px;
      min-height: 32px;

      @media (max-width: $breakpoint-l) {
        min-width: 100%;
        padding: 20px;
      }

      @media (max-width: $breakpoint-sm) {
        min-width: 100%;
        padding: 25px;
      }
    }
  }

  &__spinner {
    transform: scale(0.5);
  }
}
