@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/constants';

.AcceptancesCard {
  min-width: $card-min-width;
  .Table--relative {
    position: relative;
  }
  &__report {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 20px 0;

    Button {
      min-width: 300px;
      min-height: 32px;

      @media (max-width: $breakpoint-l) {
        min-width: 100%;
        padding: 20px;
      }

      @media (max-width: $breakpoint-sm) {
        min-width: 100%;
        padding: 25px;
      }
    }
  }
  &__report-btn-spinner {
    transform: scale(0.5);
  }

  &__spinner {
    padding: 25px;
  }

  .Table__container {
    overflow-y: auto;
    height: $modal-max-height;

    table {
      padding: 25px 20px;
      border-collapse: collapse;
      width: 100%;

      .TableRow {
        height: 30px;

        .TooltipHelp {
          margin: 2px 5px;

          &__text {
            left: -250px;
            top: 20px;
            max-width: 300px;
          }
        }

        .TooltipHelp--contained-outside {
          display: flex;
          flex-direction: row;
          span {
            width: 100px;
          }
          .TooltipHelp {
            margin-left: 78px;
            position: absolute;
          }
        }
      }
    }

    th,
    td {
      padding: 8px 15px;
    }

    td.acceptance-details {
      padding: 0;

      .Table__container {
        padding: 20px;
        height: auto;
      }
    }

    th {
      background: white;
    }
  }

  .AcceptancesDetailsTable {
    th, td {
      background: $color-gray-1;
    }
  }
}

.AcceptancesCard--no-border {
  border: none;
}
