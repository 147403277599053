@import 'styles/colors';
@import 'styles/mixins';

.InputTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;

  &__tooltip {
    margin-left: 10px;
  }
}
