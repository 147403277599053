@import 'styles/colors';

.Preview {
  &__thumbnail {
    object-fit: contain;

    &,
    img,
    video {
      max-height: inherit;
      max-width: inherit;
    }
  }
}
