.TableHeaderItem {
  padding: 0 10px;
  font-weight: 600;
  white-space: nowrap;

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }

  &__sort-icon {
    padding-left: 10px;
    font-size: 11px;
  }

  &--clickable {
    cursor: pointer;
  }
}
