@import 'styles/colors';
@import 'styles/constants';

.CampaignDetailsSummary {
  &__row {
    min-height: 78px;

    &--half {
      width: 50%;
    }

    &--inside {
      border-bottom: 1px solid $color-gray-2;
      padding: 15px 30px;

      @media (max-width: $breakpoint-l) {
        flex-direction: column;
      }

      &:first-of-type {
        border-right: 1px solid $color-gray-2;
      }
    }
  }

  &__col {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &--labels {
      align-content: flex-start;
      padding: 6.5px 0;
    }
  }

  &__title {
    font-weight: 600;
    max-width: 175px;
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    border: solid 1px $color-gray-4;
    border-radius: 4px;
    padding: 0 11px;
    margin: 5px;
    background-color: $color-gray-2;
    font-size: 13px;
    font-weight: 600;
    color: $color-gray-5;
  }

  &__buttons {
    display: flex;
    padding: 30px 30px 19px 30px;

    .Button:nth-of-type(1) {
      margin-right: 30px;
    }
  }
}
