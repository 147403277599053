@import 'styles/colors';
@import 'styles/mixins-chart';

.InventoryGalleryOccupancy {
  margin-bottom: 15px;

  &__spinner-container {
    min-height: 660px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__filters {
    display: flex;
    padding: 10px;
    gap: 20px;

    & > div {
      flex-grow: 1;
    }

    // required to fix current css
    .Input--container,
    .Input {
      width: 100%;
    }

    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput,
    .DateInput_input {
      width: 100%;
    }

    .DateInput_input {
      height: 35px;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__charts {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }

  &__pagination {
    margin: 10px 0 30px 0;
  }
}
