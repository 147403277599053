@import 'styles/colors';
@import 'styles/mixins';

.Input {
  $input-width: 375px;

  width: $input-width;
  font-family: 'Open Sans';
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  &--container {
    position: relative;
    width: $input-width;
  }

  &--extra-height {
    min-height: 67px;
  }

  &__label {
    @include transition-all-ease-in-out('transform, font-size');
    height: 17px;
    position: relative;
    top: 33px;
    left: 12px;
    color: $color-gray-3;
    cursor: text;
    padding: 0;

    &--error {
      color: $color-red;
    }

    &--nested {
      font-size: 12px;
      max-width: fit-content;
      background-color: $color-white;
      padding: 0 4px;
      left: 9px;
      transform: translateY(-25.5px);
      border-radius: 3px 3px 0 0;

      &-focused {
        color: $color-black;
      }
    }
  }

  &__field {
    @include transition-all-ease-in-out('border');
    font-family: 'Open Sans';
    color: $color-gray-5;
    caret-color: $color-gray-5;
    font-size: 14px;
    min-height: 48px;
    border-radius: 6px;
    border: solid 1px $color-gray-3;
    padding: 0 12px;

    &:focus,
    &:hover {
      outline: none;
      border: solid 1px $color-blue;
    }

    &--error {
      border: solid 1px $color-red;
    }

    &--disabled {
      background-color: $color-gray-2;

      &:focus,
      &:hover {
        border: solid 1px $color-gray-3;
      }
    }

    &::placeholder {
      color: $color-gray-3;
    }

    &:-webkit-autofill {
      animation-name: onAutoFillStart;
      -webkit-text-fill-color: $color-black;
      -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
    }

    // hide arrows from  input type `number`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

@keyframes onAutoFillStart {
}
