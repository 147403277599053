@import 'styles/constants';
@import 'styles/colors';

.MediaSelection {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &--poi-row {
    & > div:not(:first-child) {
      padding-top: 29px;
    }
  }

  &--no-filters {
    padding-top: 20px;
  }

  &__spinner {
    overflow-y: hidden;
  }

  &__no-video {
    transform: translateY(4px) scale(1.2);
  }

  &__tail {
    &--row {
      display: flex;
      align-items: center;
      padding-bottom: 5px;
    }

    &--counter {
      padding: 0 10px;
    }

    &--arrow {
      width: 10px !important;
      color: $color-gray-5;
      cursor: pointer;
    }
  }

  &__checkbox {
    &--tail {
      font-weight: 600;
    }

    &--medium {
      padding-bottom: 5px;
    }

    &--grayed-out-title .Checkbox__title {
      color: $color-gray-3;
    }
  }

  &__Filters {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .FormRow {
      justify-content: space-between;

      .InputTitle {
        font-weight: 400;
      }
    }

    .FormRow.FormRow--buildings-filters {
      justify-content: normal;
      gap: 20px;
    }

    &--actions {
      display: flex;
      align-self: center;

      .Button:nth-child(2) {
        margin-left: 15px;
      }
    }
  }

  &__MediaToImport {
    .InputTitle {
      font-weight: 400;
    }

    .Input--container,
    .Input {
      width: 100%;
    }
  }

  &__templates {
    .InputTitle {
      font-weight: normal;
    }

    .Select .Select__control {
      min-width: 200px;
    }
  }
}
