@import 'styles/colors';

.InputSearch {
  position: relative;
  $width: 295px;
  $height: 35px;
  $padding: 12px;
  $icon-width: 14px;

  .Input {
    width: $width;

    &--container {
      width: $width;
    }

    &__field {
      min-height: calc($height - 2px);
      padding-right: calc($padding * 2 + $icon-width);
    }
  }

  &__icon {
    &--container {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 $padding;
      cursor: pointer;

      &:hover .InputSearch__icon {
        color: $color-blue;
      }
    }

    width: $icon-width;
    height: $height;
    color: $color-gray-3;
  }
}
