@import 'styles/colors';
@import 'styles/constants';

.PageHeader {
  display: flex;
  align-items: center;
  font-weight: 600;
  max-width: $card-max-width;

  &__heading {
    min-height: 39px;
    font-size: 24px;
    font-weight: inherit;
    color: $color-black;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 36px;
    flex: 2;
  }

  &__link {
    margin-left: 20px;
  }

  &__border {
    display: flex;
    align-items: center;
    border: 1px solid $color-gray-2;
    border-radius: 6px;
    background-color: $color-white;
    padding-left: 28px;

    .PageHeader__heading {
      margin: 0;
    }
  }
}
