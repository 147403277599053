@import 'styles/colors';
@import 'styles/mixins';

.CampaignTableFilters {
  $spacing: 15px;
  $input-width: 200px;
  margin-bottom: 20px;

  &__search-row {
    @include table-search-row();
  }

  &__container {
    margin-top: $spacing;
    padding: $spacing*1.5 $spacing;

    .FormRow {
      gap: $spacing;
      align-items: baseline;
    }

    .InputTitle {
      font-weight: 400;
    }

    &--loading {
      display: flex;
      justify-content: center;
      padding-bottom: $spacing;
    }
  }

  &__range-filter {
    display: inherit;
    gap: inherit;
    flex-direction: column;
  }

  &__filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    $input-height: 35px;

    .DateInput_input {
      width: $input-width;
      height: $input-height;
    }

    .SingleDatePicker_picker {
      top: $input-height + 1px !important;
    }

    .Checkbox {
      display: flex;
      height: $input-height;
    }
  }

  &__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;

    .Button {
      margin-left: $spacing;
      white-space: nowrap;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
