@import 'styles/colors';
@import 'styles/constants';

.Layout {
  display: flex;
  flex-direction: column;
  background-color: $color-gray-1;
  @media print {
    background-color: $color-white;
  }

  &__content {
    display: flex;
    min-height: calc(100vh - 64px);

    &--children {
      position: relative;
      width: 100%;
      padding: 22px $layout-content-padding;
      @media print {
        padding: 0;
      }

      @media (max-width: $breakpoint-l) {
        padding: 22px;
      }

      @media (max-width: $breakpoint-sm) {
        padding: 16px;
      }
    }
  }

  .Card:last-of-type {
    margin-bottom: 0;
  }
}
