@import 'styles/colors';
@import 'styles/mixins';

.Button {
  @include transition-all-ease-in-out('background-color, box-shadow');
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: $color-white;
  font-family: 'Open Sans';
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }

  &__icon {
    font-size: inherit;

    &--margin {
      margin-right: 8px;
    }
  }

  &--standard,
  &--small {
    padding: 0 15px;

    &--only-icon {
      padding: 0 !important;
    }
  }

  &--standard {
    min-width: 135px;
    height: 40px;

    &--only-icon {
      min-width: 39px !important;
      height: 39px;

      .Button__icon {
        font-size: 18px;
      }
    }
  }

  &--small {
    min-width: 103px;
    height: 32px;

    &--only-icon {
      min-width: 30px !important;
      height: 30px;

      .Button__icon {
        font-size: 16px;
      }
    }
  }

  &--yellow {
    background-color: $color-yellow;

    &:hover {
      box-shadow: 2px 2px 10px 0 rgba(234, 234, 104, 0.7);
    }

    &:active {
      background-color: #e1f214;
    }
  }

  &--blue {
    background-color: $color-blue;

    &:hover {
      box-shadow: 2px 2px 10px 0 rgba(67, 124, 249, 0.4);
    }

    &:active {
      background-color: $color-navy-blue;
    }
  }

  &--blue-outline {
    background-color: $color-white;
    border: solid 2px $color-blue;
    color: $color-blue;

    &:hover {
      box-shadow: 2px 2px 10px 0 rgba(67, 124, 249, 0.4);
    }

    &:active {
      color: $color-white;
      background-color: $color-blue;
    }
  }

  &--navy-blue {
    background-color: $color-navy-blue;

    &:hover {
      box-shadow: 2px 2px 10px 0 rgba(58, 69, 152, 0.4);
    }

    &:active {
      color: $color-white;
      background-color: $color-blue;
    }
  }

  &--navy-blue-outline {
    background-color: $color-white;
    border: solid 1px $color-navy-blue;
    color: $color-navy-blue;

    &:hover {
      box-shadow: 2px 2px 10px 0 rgba(58, 69, 152, 0.4);
    }

    &:active {
      color: $color-white;
      background-color: $color-navy-blue;
    }
  }

  &--red {
    background-color: $color-red;
    color: $color-white;

    &:hover {
      box-shadow: 2px 2px 10px 0 rgba(253, 75, 132, 0.4);
    }

    &:active {
      color: $color-red;
      border: solid 1px $color-red;
      background-color: $color-white;
    }
  }

  &--red-outline {
    background-color: $color-white;
    border: solid 1px $color-red;
    color: $color-red;

    &:hover {
      box-shadow: 2px 2px 10px 0 rgba(253, 75, 132, 0.4);
    }

    &:active {
      color: $color-white;
      background-color: $color-red;
    }
  }

  &--blue,
  &--blue-outline,
  &--navy-blue,
  &--navy-blue-outline,
  &--red,
  &--red-outline {
    &:active {
      box-shadow: none;
    }
  }

  &--disabled {
    cursor: default;
    color: $color-white;
    background-color: $color-gray-3;
    border: none;

    &:hover,
    &:focus {
      background-color: $color-gray-3;
      box-shadow: none;
    }
  }
}
