@import 'styles/colors';

.CampaignDetailsAlerts {
  &--info {
    --border-color: #{$color-yellow};
  }
  &--warn {
    --border-color: #{$color-red};
  }
}
