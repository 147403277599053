@import 'styles/colors';

.CardNumber {
  width: 29px;
  height: 29px;
  background-color: $color-blue;
  color: $color-white;
  border-radius: 6px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
  font-weight: 600;
}
