.Features {
  &__card {
    padding-bottom: 15px;

    &--description {
      padding-bottom: 25px;
    }

    &--button {
      margin-top: 25px;
    }
  }

  &__feature {
    display: flex;
    align-items: center;

    &--name {
      min-width: 200px;
    }

    &--slider {
      width: 25px;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
}
