@import 'styles/colors';
@import 'styles/constants';

.Card {
  max-width: $card-max-width;
  background-color: $color-white;
  padding: 11px 15px 0 15px;
  border-radius: 6px;
  border: 1px solid $color-gray-2;
  margin-bottom: $card-margin-bottom;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--saved {
    border-color: $color-green;
  }

  &--no-margin-body {
    margin: 0 -15px;
  }
}
