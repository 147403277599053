.UserSettings {
  &__form {
    padding: 25px 0 0 15px;

    &--row {
      padding-bottom: 35px;
    }

    &--buttons {
      display: flex;
      padding: 45px 0 18px 0;

      .Button:first-of-type {
        margin-right: 27px;
      }
    }
  }
}
