@import 'styles/colors';
@import 'styles/constants';


.RangeSlider {
  width: calc($slider-width - 16px);

  .Range {
    margin: 0 8px;

    .rc-slider-rail {
      width: $slider-width;
      right: -7px;
    }

    .rc-slider-handle {
      margin-top: -3px;
      box-shadow: 0 0 0 4px rgba(67, 124, 249, 0.5);
    }

    .Slider__handle {
      margin-top: -28px;
    }

    .rc-slider-dot-active {
      border-color: $color-blue;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      box-shadow: 0 0 0 4px rgba(67, 124, 249, 0.5);
    }
  }

  &__labels {
    width: calc($slider-width);
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    color: $color-gray-4;
    padding-top: 8px;
  }
}



