@import 'styles/mixins_table';
@import 'styles/mixins';
@import 'styles/colors';

@include common-table;

.UserTable {
  &__name {
    min-width: 140px;
  }

  &__agency.UserTable__agency {
    width: 100%;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__status-label {
    width: 120px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    font-size: 13px;
    font-weight: 600;
    color: $color-white;

    &--active {
      background-color: $color-green;
    }

    &--invited {
      background-color: $color-navy-blue;
    }

    &--deactivated {
      background-color: $color-gray-2;
      color: $color-black;
    }

    &--subscription {
      background-color: $color-green-dark;
    }
  }
}

.no-data {
  margin-top: 12px
}

.CommonTable__search-row {
  @include table-search-row();
}
