@import 'styles/colors';

.MediaList {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 840px;
  min-height: 549px;
  background-color: $color-white;

  &--loading-container {
    padding: 15px 0;
    min-height: inherit;
  }

  &__table {
    padding-bottom: 7px;

    &--row {
      height: 50px;
    }

    &--grayed-out {
      color: $color-gray-3;
    }

    &--bold {
      font-weight: 600;
    }
  }
}
