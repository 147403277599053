@import 'styles/colors';
@import 'styles/mixins';

$sdp-input-height: 50px;

.SingleDatePicker__wrapper {
  position: relative;

  &--error {
    margin-top: 3px;
  }

  input.error {
    border: solid 1px $color-red;

    &:focus {
      border: solid 1px $color-blue;
    }
  }
}

.DayPicker {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px $color-gray-3;
  border-radius: 2px;
  width: fit-content !important;

  &_weekHeader {
    top: 60px;

    &_ul {
      font-weight: 600;
      color: $color-gray-4;
      font-size: 13px;
    }
  }

  &Navigation {
    color: $color-gray-4;
  }
}

.CalendarMonth {
  strong {
    font-weight: 600;
  }

  &_caption {
    text-transform: capitalize;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 55px;
  }
}

.CalendarDay {
  border: none !important;
  border-radius: 6px !important;

  &__default:hover {
    background-color: $color-gray-1 !important;
  }

  &__selected {
    background-color: $color-blue !important;

    &:hover {
      background-color: $color-navy-blue !important;
    }
  }

  &__blocked_out_of_range {
    color: $color-gray-2 !important;

    &:hover {
      background-color: $color-white !important;
    }
  }
}

.DayPickerNavigation {
  &_button {
    position: absolute;

    &:nth-child(1),
    &:nth-child(2) {
      top: 16px;
    }

    &:nth-child(1) {
      left: 20px;
    }
    &:nth-child(2) {
      right: 20px;
    }
  }
}

.SingleDatePicker {
  &_picker {
    top: $sdp-input-height + 1px !important;
    z-index: 2;

    &:after {
      content: '';
      display: block;
      border-top: 1px solid $color-gray-2;
      position: absolute;
      top: 50px;
      width: 100%;
    }
  }

  &Input {
    border: none;
  }
}

.DayPicker_transitionContainer__horizontal {
  transition: none;
}

.SingleDatePickerInput__disabled {
  .DateInput_input {
    &:focus,
    &:hover {
      border: solid 1px $color-gray-3 !important;
    }
  }

  .DateInput_input__disabled {
    background-color: $color-gray-2;
    color: $color-black;
    font-style: normal;
  }
}

.DateInput {
  width: fit-content;

  &_input {
    @include transition-all-ease-in-out('border');
    width: 158px;
    height: $sdp-input-height;
    font-family: 'Open Sans';
    color: $color-black;
    caret-color: $color-black;
    font-size: 14px;
    border-radius: 6px;
    border: solid 1px $color-gray-3;
    padding: 0 0 0 12px;
    box-sizing: border-box;
    &:focus,
    &:hover {
      outline: none;
      border: solid 1px $color-blue;
    }

    &::placeholder {
      color: $color-gray-3;
    }
  }

  &_fang {
    display: none;
  }
}
