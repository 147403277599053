@import 'styles/colors';
@import 'styles/constants';

.AttributesModal {
  width: 800px;
  min-height: 180px;
  max-height: calc($modal-max-height + 120px);

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    .Checkbox__title {
      padding-left: 7px;
    }
  }

  &--dg-in {
    width: 900px;
  }

  &__template {
    &-footer {
      display: flex;
      gap: 10px;
    }

    &-label {
      display: flex;
      justify-content: space-between;
    }
  }
}

.Select__option.Select__option--is-selected {
  .Link {
    color: white;
  }
}

.AttributesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.OrganizationAttributes {
  &__checkbox {
    margin-bottom: 10px;

    &--nested {
      margin-left: 20px;
    }
  }

  &__campaignInput {
    margin: 0 0 10px 20px;
    width: auto;
  }
}
